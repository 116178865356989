<template>
    <Dialog v-model:open="isOpen">
        <DialogContent :id="'distributors-modal'" class="md:max-w-6xl max-h-[80vh] overflow-y-scroll">
            <DialogHeader>
                {{ $t("BOMTool.AddOrRemoveDistributors") }}
            </DialogHeader>
            <ButtonGroup>
                <input
                    v-model="availableDistributorsFilter"
                    type="text"
                    :placeholder="$t('BOMTool.FilterDistributors')" />
                <Button variant="default" fill="none" class="h-full" :title="$t('Layout.SearchButtonTitle')">
                    <span class="fas fa-magnifying-glass" aria-hidden="true" />
                </Button>
            </ButtonGroup>
            <div class="md:flex gap-4">
                <div class="w-full">
                    <table class="table table-striped">
                        <thead>
                            <tr class="text-left">
                                <th>
                                    {{ $t("Global.Show") }}
                                </th>
                                <th>
                                    <Button variant="white" size="sm" @click="sort = 'name'">
                                        <span>
                                            {{ $t("BOMTool.AvailableDistributors") }}
                                            ({{ availableDistributors.length }})
                                        </span>
                                        <i class="fas fa-caret-down" />
                                    </Button>
                                </th>
                                <th v-if="bomCoverage">
                                    <Button variant="white" @click="sort = 'coverage'">
                                        <span>{{ $t("BOMTool.BOMCoverage") }}</span>
                                        <i class="fas fa-caret-down" />
                                    </Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="dist in availableDistributors" :key="dist.Id">
                                <td>
                                    <input type="checkbox" @click="addDistributor(dist)" />
                                </td>
                                <td>
                                    <span v-if="!dist.IsSponsor">{{ dist.Name }}</span>
                                    <b v-if="dist.IsSponsor">{{ dist.Name }}</b>
                                </td>
                                <td v-if="bomCoverage">{{ bomCoverage[dist.Id] }}%</td>
                            </tr>
                            <tr v-show="!availableDistributors || !availableDistributors.length">
                                <td v-show="!availableDistributorsFilter" colspan="2">
                                    {{ $t("BOMTool.NoAvailableDistributors") }}
                                </td>
                                <td v-show="availableDistributorsFilter" colspan="2">
                                    {{ $t("BOMTool.NoAvailableDistributorsMatching") }} "{{
                                        availableDistributorsFilter
                                    }}"
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="w-full">
                    <table class="table table-striped">
                        <thead>
                            <tr class="text-left">
                                <th />
                                <th>{{ $t("Global.Show") }}</th>
                                <th>
                                    <Button variant="white" size="sm" @click="selectedSort = 'name'">
                                        <span>
                                            {{ $t("BOMTool.SelectedDistributors") }} ({{ activeDistributors.length }})
                                        </span>
                                        <i class="fas fa-caret-down" />
                                    </Button>
                                </th>
                                <th v-if="bomCoverage">
                                    <Button variant="white" size="sm" @click="selectedSort = 'coverage'">
                                        <span>{{ $t("BOMTool.BOMCoverage") }}</span>
                                        <i class="fas fa-caret-down" />
                                    </Button>
                                </th>
                            </tr>
                        </thead>
                        <VueDraggableNext
                            tag="tbody"
                            :list="sortedSelectedDistributors"
                            handle=".handle"
                            item-key="Id"
                            @change="save">
                            <tr v-for="dist in sortedSelectedDistributors" :key="dist.Id">
                                <td class="handle cursor-grab">
                                    <i class="fas fa-bars" />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked
                                        :disabled="activeDistributors.length == 1"
                                        @click="removeDistributor(dist)" />
                                </td>
                                <td>
                                    <span style="{'font-bold': dist.IsSponsor}">{{ dist.Name }}</span>
                                </td>
                                <td v-if="bomCoverage">{{ bomCoverage[dist.Id] }}%</td>
                            </tr>
                        </VueDraggableNext>
                    </table>
                </div>
            </div>
            <DialogFooter>
                <DialogClose as-child>
                    <Button variant="default" @click="closeDialog">
                        {{ $t("Global.Close") }}
                    </Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
<script setup lang="ts">
import { VueDraggableNext } from "vue-draggable-next";

defineExpose({ openDialog });

type SortType = "name" | "coverage" | "";

const props = defineProps<{
    distributors: BOMSearchDistributor[];
    defaultSelections: BOMSearchDistributor[];
    bomCoverage?: {
        [key: string]: number;
    };
}>();

const emit = defineEmits(["save"]);

const availableDistributorsFilter = ref("");
const sort = ref<SortType>("");
const selectedSort = ref<SortType>("");
const activeDistributors = ref<BOMSearchDistributor[]>(props.defaultSelections);
const isOpen = ref(false);

const availableDistributors = computed(() => {
    const selectedDistsIds = activeDistributors.value.map(function (d) {
        return d.Id;
    });

    const filteredDists = props.distributors.filter((dist) => {
        if (selectedDistsIds.indexOf(dist.Id) >= 0) {
            return false;
        }

        if (dist.Name.toLowerCase().startsWith(availableDistributorsFilter.value.toLowerCase())) {
            return true;
        }

        return false;
    });

    return sortDistributors(filteredDists, sort.value);
});

const sortedSelectedDistributors = computed(() => {
    if (!activeDistributors.value) {
        return [];
    }

    return sortDistributors(activeDistributors.value, selectedSort.value);
});

function openDialog() {
    isOpen.value = true;
}
function closeDialog() {
    sort.value = "";
    selectedSort.value = "";
    isOpen.value = false;
    save();
}
function addDistributor(dist: BOMSearchDistributor) {
    if (activeDistributors.value) {
        activeDistributors.value.push(dist);
    }
    save();
}
function save() {
    emit("save");
}
function removeDistributor(dist: BOMSearchDistributor) {
    if (!activeDistributors.value) return;

    let index = -1;
    for (let i = 0; i < activeDistributors.value.length; i++) {
        if (activeDistributors.value[i].Id == dist.Id) {
            index = i;
            break;
        }
    }

    activeDistributors.value.splice(index, 1);
    save();
}
function sortDistributors(distributors: BOMSearchDistributor[], sort: string) {
    if (sort === "name") {
        return distributors.slice().sort((a, b) => a.Name.localeCompare(b.Name));
    }

    if (sort === "coverage") {
        return distributors.slice().sort((a, b) => {
            if (!props.bomCoverage) {
                return 0;
            }

            const coverageA = props.bomCoverage[a.Id];
            const coverageB = props.bomCoverage[b.Id];

            if (coverageA > coverageB) {
                return -1;
            }
            if (coverageA < coverageB) {
                return 1;
            }

            return 0;
        });
    }

    return distributors;
}
</script>
