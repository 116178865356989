<template>
    <Dialog v-model:open="isOpen">
        <DialogContent id="bom-share-modal" class="md:max-w-3xl">
            <div v-if="isOwner" class="flex flex-col gap-4">
                <div v-if="error" class="alert alert-danger" v-html="error" />

                <div class="flex items-center justify-between">
                    <span class="text-primary font-bold">{{ headerText }}</span>

                    <div class="flex justify-between items-center uppercase">
                        <Button
                            v-if="!bom.EnableLinkSharing"
                            variant="default"
                            class="uppercase"
                            @click="setLinkSharing(true)"
                            >{{ $t("BOMTool.CreateShareableLink") }}</Button
                        >
                        <div v-else class="flex gap-2 items-center">
                            <span class="text-uppercase">{{ $t("BOMTool.LinkSharingOn") }}</span>
                            <Button variant="default" @click="setLinkSharing(false)">{{
                                $t("BOMTool.TurnOff")
                            }}</Button>
                        </div>
                    </div>
                </div>

                <div v-if="bom.EnableLinkSharing">
                    <div class="flex justify-between items-center bg-gray-100 p-2">
                        <span>
                            <strong>{{ bom.Name }}:</strong> {{ $t("BOMTool.AnyoneWithLinkCanView") }}
                        </span>
                        <Button variant="link" @click="copyLink">{{ $t("BOMTool.CopyLink") }}</Button>
                    </div>
                    <div class="border border-gray-100 p-2">
                        <input id="share-link" class="w-full" type="text" readonly :value="bom.ShareLink" />
                    </div>
                </div>

                <div class="flex gap-2 flex-wrap">
                    <Button v-for="e in emails" :key="e" variant="default" fill="solid" @click="removeEmail(e)">
                        <span>{{ e }}</span>
                        <i class="fass fa-xmark" />
                    </Button>
                </div>

                <div class="flex gap-2">
                    <div class="grow">
                        <div class="w-full flex gap-2" @click="focusInput">
                            <input
                                id="bom-share-email-input"
                                v-model="email"
                                class="w-full"
                                type="text"
                                :size="email.length || 20"
                                :placeholder="$t('Global.EnterEmailAddresses')"
                                @keyup.enter="addEmail()" />
                        </div>
                    </div>
                    <select v-model="canEdit">
                        <option :value="true">
                            {{ $t("BOMTool.CanEdit") }}
                        </option>
                        <option :value="false">
                            {{ $t("BOMTool.CanView") }}
                        </option>
                    </select>
                </div>

                <div class="text-danger">{{ emailError }}</div>

                <div v-for="user in sharedUsers" :key="user.Id" class="flex items-center justify-between gap-2">
                    <div class="grow">{{ user.EmailAddress }}</div>
                    <div class="flex items-center gap-2">
                        <div v-if="hasDeleteTimeout(user)">
                            <select v-model="user.CanEdit" @change="updateUser(user)">
                                <option :value="true">
                                    {{ $t("BOMTool.CanEdit") }}
                                </option>
                                <option :value="false">
                                    {{ $t("BOMTool.CanView") }}
                                </option>
                            </select>
                        </div>

                        <Button
                            v-if="hasDeleteTimeout(user)"
                            variant="danger"
                            fill="solid"
                            @click="deleteUserStart(user)">
                            <i class="fas fa-trash-can" />
                        </Button>
                        <Button v-else variant="default" @click="undoDeleteUser(user)">
                            {{ $t("Global.Undo") }}
                        </Button>
                    </div>
                </div>

                <div v-if="emails.length || email.length" class="flex gap-2">
                    <Button v-if="!sent" variant="primary" fill="solid" :disabled="sending" @click="addUsers">
                        <span v-show="!sending">{{ $t("Global.Send") }}</span>
                        <span v-show="sending">{{ $t("Global.Sending") }}</span>
                    </Button>
                    <Button v-else variant="success" fill="solid">
                        <span>{{ $t("Global.Sent") }}</span>
                    </Button>
                    <Button variant="default" @click="closeDialog">
                        {{ $t("Global.Cancel") }}
                    </Button>
                </div>

                <Button v-else variant="primary" fill="solid" class="self-start" @click="closeDialog">
                    {{ $t("Global.Done") }}
                </Button>
            </div>
            <div v-else-if="!isPublic">
                <p class="text-primary text-uppercase font-bold">{{ $t("BOMTool.WhoHasAccess") }}</p>
                <p>{{ bom.OwnerName }} ({{ $t("BOMTool.Owner") }})</p>

                <p v-for="user in sharedUsers" :key="user.Id">{{ user.FriendlyName }} ({{ user.EmailAddress }})</p>

                <Button variant="primary" @click="closeDialog">
                    {{ $t("Global.Done") }}
                </Button>
            </div>
            <p v-else class="text-primary text-uppercase font-bold">{{ $t("BOMTool.BOMReadOnlyMessage") }}</p>
        </DialogContent>
    </Dialog>
</template>
<script setup lang="ts">
import { BOM_TOOL } from "~/utils/constants";
import helpers from "../../utils/PageHelpers.js";

defineExpose({ openDialog });

const { t } = useI18n();
const { gtag } = useGtag();
const api = useApi();
const { toast } = useToast();

type deleteUsersType = {
    email: string;
    timeoutFunction: number;
};

const props = defineProps<{
    bom: BOMSearch;
    sharedUsers: Array<BOMSearchUser>;
}>();

const isOpen = ref(false);
const email = ref("");
const emails = ref<string[]>([]);
const canEdit = ref(true);
const emailError = ref("");
const error = ref("");
const sending = ref(false);
const sent = ref(false);
const deleteUsers = ref<deleteUsersType[]>([]);

const emit = defineEmits(["shared-users-changed", "enable-link-sharing-changed"]);

const headerText = computed(() => {
    return t("BOMTool.ShareBOMHeader", [props.bom.Name]);
});
const isOwner = computed(() => {
    return props.bom.BOMPermissionLevel == BOM_TOOL.BOM_PERMISSION_LEVELS.OWNER;
});
const isPublic = computed(() => {
    return props.bom.BOMPermissionLevel == BOM_TOOL.BOM_PERMISSION_LEVELS.PUBLIC;
});

function openDialog() {
    isOpen.value = true;

    // reset data on open
    email.value = "";
    emails.value = [];
    canEdit.value = true;
    emailError.value = "";
    error.value = "";
    sending.value = false;

    trackEvent("bom_tool_share");
}
function closeDialog() {
    isOpen.value = false;
}
function trackEvent(action: string) {
    gtag("event", action, { bom_hash: props.bom.HashId });
}
async function setLinkSharing(flag: boolean) {
    if (flag) {
        trackEvent("bom_tool_share_link");
    } else {
        trackEvent("bom_tool_share_link_disable");
    }

    emit("enable-link-sharing-changed", flag);

    try {
        await api(`/api/bom/${props.bom.HashId}/linksharing`, {
            method: "PUT",
            body: {
                EnableLinkSharing: flag,
            },
        });
    } catch (_error) {
        error.value = t("Global.GenericError");
    }
}
function copyLink() {
    const copyTextElement = document.getElementById("share-link") as HTMLInputElement;
    const copyText = copyTextElement?.value;

    if (copyText) {
        navigator.clipboard.writeText(copyText).then(() => {
            toast({ title: t("BOMTool.LinkCopiedToClipboard"), variant: "default", duration: 5000 });
        });
    }
}
function validateEmail() {
    emailError.value = "";

    if (!email.value) {
        emailError.value = t("Global.EmailRequired");
        return false;
    }

    if (!helpers.IsValidEmail(email.value)) {
        emailError.value = t("Global.InvalidEmail");
        return false;
    }

    return true;
}
function focusInput() {
    document.getElementById("bom-share-email-input")?.focus();
}
function addEmail() {
    if (!validateEmail()) {
        return;
    }

    emails.value.push(email.value);
    email.value = "";
}
function removeEmail(email: string) {
    const index = emails.value.indexOf(email);
    if (index > -1) {
        emails.value.splice(index, 1);
    }
}
async function addUsers() {
    if (email.value.length) {
        // stranded text didn't get entered yet
        addEmail();
    }

    if (!emails.value.length) {
        return;
    }

    emailError.value = "";
    error.value = "";
    sending.value = true;

    const request = [];
    for (const email of emails.value) {
        request.push({
            EmailAddress: email,
            CanEdit: canEdit.value,
        });
    }

    if (canEdit.value) {
        trackEvent("bom_tool_share_add_editor");
    } else {
        trackEvent("bom_tool_share_add_viewer");
    }

    try {
        const response = await api<BOMSearchUser[]>(`/api/bom/${props.bom.HashId}/share`, {
            method: "POST",
            body: request,
        });

        if (response) {
            sending.value = false;

            if (response?.length) {
                emit("shared-users-changed", response);
            }

            sent.value = true;

            // reset the input
            email.value = "";
            emails.value = [];

            setTimeout(() => {
                sent.value = false;
            }, 2000);
        }
    } catch (_error) {
        error.value = t("Global.GenericError");
    }

    sending.value = false;
}
async function updateUser(user: BOMSearchUser) {
    try {
        await api(`/api/bom/${props.bom.HashId}/share/${user.Id}`, {
            method: "PUT",
            body: user,
        });
    } catch (_error) {
        error.value = t("Global.GenericError");
    }

    if (user.CanEdit) {
        trackEvent("bom_tool_share_update_editor");
    } else {
        trackEvent("bom_tool_share_update_viewer");
    }
}
function deleteUserStart(user: BOMSearchUser) {
    const timeout = window.setTimeout(() => {
        deleteUser(user);
    }, 3000);

    deleteUsers.value.push({ email: user.EmailAddress, timeoutFunction: timeout });
}
function undoDeleteUser(user: BOMSearchUser) {
    const findUser = deleteUsers.value.find((u) => u.email === user.EmailAddress);
    const position = deleteUsers.value.map((u) => u.email).indexOf(user.EmailAddress);

    if (findUser && position > -1) {
        clearTimeout(findUser.timeoutFunction);
        deleteUsers.value.splice(position, 1);
    }
}
function hasDeleteTimeout(user: BOMSearchUser) {
    return !deleteUsers.value.find((u) => u.email === user.EmailAddress);
}
async function deleteUser(user: BOMSearchUser) {
    const position = deleteUsers.value.map((u) => u.email).indexOf(user.EmailAddress);

    if (position > -1) {
        deleteUsers.value.splice(position, 1);
    }

    try {
        await api<{ success: boolean }>(`/api/bom/${props.bom.HashId}/share/${user.Id}`, {
            method: "DELETE",
            body: user,
        });
    } catch (_error) {
        error.value = t("Global.GenericError");
    }

    emit(
        "shared-users-changed",
        props.sharedUsers.filter((u) => u.Id != user.Id)
    );

    if (user.CanEdit) {
        trackEvent("bom_tool_share_remove_editor");
    } else {
        trackEvent("bom_tool_share_remove_viewer");
    }
}
</script>
