<template>
    <Dialog v-model:open="isOpen">
        <DialogContent id="multi-bom-share-modal" class="md:max-w-3xl">
            <div class="flex flex-col gap-4">
                <div v-if="error" class="alert alert-danger" v-html="error" />

                <div class="flex items-center justify-between">
                    <span class="text-primary font-bold">{{ headerText }}</span>

                    <div v-if="validBoms.length">
                        <Button
                            v-if="showCreateShareLink"
                            variant="default"
                            class="text-uppercase"
                            @click="setLinkSharing(true)">
                            {{ $t("BOMTool.CreateShareableLink") }}
                        </Button>
                        <div v-else class="flex gap-2 items-center">
                            <span class="text-uppercase">{{ $t("BOMTool.LinkSharingOn") }}</span>
                            <Button variant="default" @click="setLinkSharing(false)">
                                {{ $t("BOMTool.TurnOff") }}
                            </Button>
                        </div>
                    </div>
                </div>

                <div v-for="bom in linkShareBoms" :key="bom.HashId">
                    <div class="flex justify-between items-center bg-gray-100 p-2">
                        <span>
                            <strong>{{ bom.Name }}:</strong> {{ $t("BOMTool.AnyoneWithLinkCanView") }}
                        </span>
                        <Button variant="link" @click="copyLink(bom)">{{ $t("BOMTool.CopyLink") }}</Button>
                    </div>
                    <div class="border border-gray-100 p-2">
                        <input
                            :id="`${SHARE_LINK_PREFIX}${bom.HashId}`"
                            ref="bomLinks"
                            type="text"
                            class="w-full"
                            readonly
                            :value="bom.ShareLink" />
                    </div>
                </div>

                <div class="flex gap-2 flex-wrap">
                    <Button v-for="e in emails" :key="e" variant="default" fill="solid" @click="removeEmail(e)">
                        <span>{{ e }}</span>
                        <i class="fass fa-xmark" />
                    </Button>
                </div>

                <div v-if="validBoms.length" class="flex gap-2">
                    <div class="grow">
                        <input
                            v-model="email"
                            class="w-full"
                            type="text"
                            :size="email.length || 20"
                            :placeholder="$t('Global.EnterEmailAddresses')"
                            @keyup.enter="addEmail()" />
                        <span class="text-danger">{{ emailError }}</span>
                    </div>
                    <div>
                        <select v-model="canEdit">
                            <option :value="true">
                                {{ $t("BOMTool.CanEdit") }}
                            </option>
                            <option :value="false">
                                {{ $t("BOMTool.CanView") }}
                            </option>
                        </select>
                    </div>
                </div>

                <p v-for="bom in invalidBoms" :key="bom.HashId" class="text-danger">
                    {{ getNotOwnerErrorMessage(bom.Name) }}
                </p>

                <div v-if="emails.length || email.length" class="flex gap-2">
                    <Button v-if="!sent" variant="primary" fill="solid" :disabled="sending" @click="addUsers">
                        <span v-show="!sending">{{ $t("Global.Send") }}</span>
                        <span v-show="sending">{{ $t("Global.Sending") }}</span>
                    </Button>
                    <Button v-else type="button" variant="success">
                        <span>{{ $t("Global.Sent") }}</span>
                    </Button>
                    <Button type="button" variant="default" @click="closeDialog">
                        {{ $t("Global.Cancel") }}
                    </Button>
                </div>

                <div v-else class="flex gap-2">
                    <Button variant="primary" fill="solid" @click="closeDialog">
                        {{ $t("Global.Done") }}
                    </Button>
                </div>
            </div>
        </DialogContent>
    </Dialog>
</template>

<script setup lang="ts">
import { BOM_TOOL } from "../../utils/constants.js";
import helpers from "../../utils/PageHelpers.js";
import { useClipboard } from "@vueuse/core";

defineExpose({ openDialog });

const SHARE_LINK_PREFIX = "share-link-";
const bomLinkRefs = useTemplateRef<HTMLInputElement[]>("bomLinks");
const { t } = useI18n();
const { gtag } = useGtag();
const api = useApi();
const { toast } = useToast();
const { copy, copied } = useClipboard();

const props = defineProps<{ boms: BOMSearch[] }>();

const isOpen = ref(false);
const email = ref("");
const emails = ref<string[]>([]);
const canEdit = ref(true);
const emailError = ref("");
const error = ref("");
const sending = ref(false);
const sent = ref(false);

const bomNames = computed(() => {
    return props.boms.map((x) => x.Name).join(", ");
});
const headerText = computed(() => {
    return t("BOMTool.ShareBOMHeader", [bomNames.value]);
});
const validBoms = computed(() => {
    return props.boms.filter((x) => x.BOMPermissionLevel == BOM_TOOL.BOM_PERMISSION_LEVELS.OWNER);
});
const invalidBoms = computed(() => {
    return props.boms.filter((x) => x.BOMPermissionLevel != BOM_TOOL.BOM_PERMISSION_LEVELS.OWNER);
});
const linkShareBoms = computed(() => {
    return props.boms.filter((x) => x.EnableLinkSharing);
});
const showCreateShareLink = computed(() => {
    return props.boms.some((x) => !x.EnableLinkSharing);
});

function openDialog() {
    isOpen.value = true;

    // reset data on open
    email.value = "";
    emails.value = [];
    canEdit.value = true;
    emailError.value = "";
    error.value = "";
    sending.value = false;

    trackEvent("bom_tool_share");
}

function closeDialog() {
    isOpen.value = false;
}

function trackEvent(action: string) {
    for (const bom of validBoms.value) {
        gtag("event", action, { bom_hash: bom.HashId });
    }
}
async function setLinkSharing(isEnabled: boolean) {
    if (isEnabled) {
        trackEvent("bom_tool_share_link");
    } else {
        trackEvent("bom_tool_share_link_disable");
    }

    for (const bom of validBoms.value) {
        bom.EnableLinkSharing = isEnabled;

        try {
            await api(`/api/bom/${bom.HashId}/linksharing`, {
                method: "PUT",
                body: {
                    EnableLinkSharing: isEnabled,
                },
            });
        } catch (_error) {
            error.value = t("Global.GenericError");
        }
    }
}
async function copyLink(bom: BOMSearch) {
    if (!bomLinkRefs.value) return;

    const bomLink = bomLinkRefs.value.find((item) => item.id === `${SHARE_LINK_PREFIX}${bom.HashId}`);
    const bomLinkText = bomLink?.value;

    if (!bomLinkText) return;

    await copy(bomLinkText);

    if (copied.value) {
        toast({ title: t("BOMTool.LinkCopiedToClipboard"), variant: "default", duration: 5000 });
    }
}
function validateEmail() {
    emailError.value = "";

    if (!email.value) {
        emailError.value = t("Global.EmailRequired");
        return false;
    }

    if (!helpers.IsValidEmail(email.value)) {
        emailError.value = t("Global.InvalidEmail");
        return false;
    }

    return true;
}
function addEmail() {
    if (!validateEmail()) {
        return;
    }

    emails.value.push(email.value);
    email.value = "";
}
function removeEmail(email: string) {
    const index = emails.value.indexOf(email);
    if (index > -1) {
        emails.value.splice(index, 1);
    }
}
async function addUsers() {
    if (email.value.length) {
        // stranded text didn't get entered yet
        addEmail();
    }

    if (!emails.value.length) {
        return;
    }

    sending.value = true;

    const request = [];
    for (const email of emails.value) {
        request.push({
            EmailAddress: email,
            CanEdit: canEdit.value,
        });
    }

    if (canEdit.value) {
        trackEvent("bom_tool_share_add_editor");
    } else {
        trackEvent("bom_tool_share_add_viewer");
    }

    const promises = [];

    for (const bom of validBoms.value) {
        promises.push(
            (async () => {
                const response = await api<BOMSearchUser[]>(`/api/bom/${bom.HashId}/share`, {
                    method: "POST",
                    body: request,
                });

                bom.SharedUsers = response;
            })()
        );
    }

    try {
        await Promise.all(promises);
    } catch (_error) {
        error.value = t("Global.GenericError");
    }

    sending.value = false;
    sent.value = true;
    setTimeout(() => {
        sent.value = false;
        isOpen.value = false;
    }, 2000);
}
function getNotOwnerErrorMessage(bomName: string) {
    return t("BOMTool.NotOwnerErrorMessage").replace("{0}", bomName);
}
</script>
