<template>
    <section class="flex flex-col gap-4 my-account">
        <MyAccountLegend :help-link="helpLink">
            {{ $t("MyAccount.MySavedBOMs") }}
        </MyAccountLegend>

        <div class="flex gap-2">
            <Button variant="primary" fill="solid" :disabled="overLimit" @click="openStartNewBom">
                {{ $t("MyAccount.StartNewBOMSearch") }}
            </Button>
            <Button variant="accent" @click="openDistsDialog">
                {{ $t("MyAccount.EditDefaultDistributors") }}
            </Button>
        </div>

        <div v-show="overLimit" class="alert alert-warning">
            <span>{{ $t("MyAccount.MaxBOMsCount") }}</span>
        </div>

        <div v-show="error" class="alert alert-danger">
            <span v-html="error" />
        </div>

        <Spinner v-if="isBusy" class="mx-auto" />

        <table v-if="showTable" class="table table-striped">
            <thead>
                <tr class="text-left">
                    <th />
                    <th>{{ $t("MyAccount.BOMName") }}</th>
                    <th>{{ $t("BOMTool.Owner") }}</th>
                    <th>{{ $t("MyAccount.NumberOfParts") }}</th>
                    <th>{{ $t("MyAccount.Selected") }}</th>
                    <th>{{ $t("MyAccount.LastModifiedDate") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td />
                    <td>
                        <input v-model="bomFilter" type="text" :placeholder="$t('MyAccount.BOMFilter')" />
                    </td>
                    <td colspan="4" />
                </tr>
                <tr v-for="bom in filteredBoms" :key="bom.HashId">
                    <td>
                        <input v-model="bom.IsSelected" type="checkbox" />
                    </td>
                    <td>
                        <span v-show="!bom.EditMode">
                            <a :href="getBOMUrl(bom)" :title="bom.Name">{{ bom.Name }}</a>
                            <Button v-if="!bom.IsLocked" variant="link" class="ml-2 p-0 h-auto" @click="editBom(bom)">
                                <i v-if="!isReadOnly(bom)" class="fas fa-pen-to-square" />
                            </Button>
                        </span>
                        <div
                            v-show="bom.EditMode"
                            class="flex gap-1 items-center"
                            :class="{ 'has-error': !bom.Name.trim() }">
                            <input
                                :id="`${BOM_NAME_PREFIX}${bom.HashId}`"
                                ref="items"
                                v-model="bom.Name"
                                type="text"
                                :placeholder="$t('BOMTool.BOMNamePlaceHolder')"
                                @keyup.enter="saveBom($event, bom)"
                                @blur="saveBom($event, bom)" />
                            <span v-show="!bom.Name.trim()" class="text-danger">{{
                                $t("BOMTool.BOMNameRequired")
                            }}</span>
                        </div>
                    </td>
                    <td>
                        <Button
                            :title="$t('BOMTool.ShareBOM')"
                            variant="link"
                            class="p-0 h-auto"
                            @click="shareBom(bom)">
                            <div>
                                {{ bom.OwnerName }}
                                <i
                                    v-show="bom.EnableLinkSharing || (bom.SharedUsers && bom.SharedUsers.length)"
                                    class="fas fa-user" />
                            </div>
                            <div v-if="bom.IsLocked">{{ getBOMLockedMessage(bom) }}</div>
                        </Button>
                    </td>
                    <td>{{ bom.PartCount }}</td>
                    <td>{{ bom.PartSelectionCount }}</td>
                    <td>{{ bom.TimeStamp }}</td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-end items-center gap-2">
            <span v-show="selectedBoms.length">{{ selectedBoms.length }} BOMs Selected</span>
            <Button variant="primary" fill="solid" :disabled="!selectedBoms.length" @click="shareSelectedBoms">
                {{ $t("BOMTool.ShareBOM") }}
                <span v-if="selectedBoms.length" class="ml-2">({{ selectedBoms.length }})</span>
            </Button>
            <Button variant="danger" fill="solid" :disabled="!selectedBoms.length" @click="deleteBOMs">
                {{ $t("BOMTool.DeleteBOM") }}
                <span v-if="selectedBoms.length" class="ml-2">({{ selectedBoms.length }})</span>
            </Button>
        </div>
        <p v-if="!isBusy && !filteredBoms.length" class="text-center">
            <span v-if="bomFilter">{{ $t("MyAccount.NoBomsForFilter") }} "{{ bomFilter }}"</span>
            <span v-else>{{ $t("MyAccount.NoBoms") }}</span>
        </p>

        <BomConfirmDeleteDialog ref="bomConfirmDialog" @delete="onDelete" />
        <BomDistsDialog
            v-if="bomDistributors"
            ref="bomDistsDialog"
            :distributors="bomDistributors"
            :default-selections="selectedDists"
            @save="saveUserPreferences" />
        <BomMultiSharingDialog ref="bomMultiSharingDialog" :boms="selectedBoms" />
        <BomSharingDialog
            v-if="selectedBom"
            ref="bomSharingDialog"
            :bom="selectedBom"
            :shared-users="selectedBom.SharedUsers"
            @shared-users-changed="sharedUsersChanged"
            @enable-link-sharing-changed="enableLinkSharingChanged" />
    </section>
</template>

<script setup lang="ts">
import { nextTick } from "vue";
import { BOM_TOOL } from "~/utils/constants";
import { BomSharingDialog, BomMultiSharingDialog, BomDistsDialog } from "#components";

const { t } = useI18n();
const localePath = useLangPath();
const api = useApi();

type BOMPreferencesType = {
    DefaultDistributorIds: number[];
};

type BOMSearchEdit = BOMSearch & {
    EditMode?: boolean;
    OriginalName?: string;
    IsSelected?: boolean;
};

const BOM_NAME_PREFIX = "bomname";
const inputRefs = useTemplateRef<HTMLInputElement[]>("items");
const bomFilter = ref("");
const error = ref("");
const bomSharingDialog = ref<typeof BomSharingDialog | null>(null);
const bomMultiSharingDialog = ref<typeof BomMultiSharingDialog | null>(null);
const [
    { data: bomDistributors, status: bomDistributorsStatus },
    { data: bomPreferences, status: bomPreferencesStatus },
    { data: boms, status: bomsStatus, refresh: refreshBoms },
] = await Promise.all([
    useFetchLocaleApi<BOMSearchDistributor[]>("api/bom/distributors"),
    useFetchLocaleApi<BOMPreferencesType>("api/account/bom-preferences"),
    useFetchLocaleApi<BOMSearchEdit[]>("api/bom"),
]);
const isBusy = computed(() =>
    [bomDistributorsStatus.value, bomPreferencesStatus.value, bomsStatus.value].some((status) => status === "pending")
);
const selectedDists = computed(() => {
    if (!Array.isArray(bomPreferences.value?.DefaultDistributorIds)) return [];
    if (!Array.isArray(bomDistributors.value)) return [];

    return bomDistributors.value.filter((dist) => bomPreferences.value?.DefaultDistributorIds.includes(dist.Id));
});
const showTable = computed(() => !isBusy.value && boms.value?.length);
const bomConfirmDialog = useTemplateRef("bomConfirmDialog");
const bomDistsDialog = useTemplateRef("bomDistsDialog");
const startNewBOMLink = computed(() => {
    return localePath("/bom/import");
});
const filteredBoms = computed(() => {
    if (!Array.isArray(boms.value)) return [];

    return boms.value?.filter((bom) => {
        return bom.Name.toLowerCase().startsWith(bomFilter.value.toLowerCase());
    });
});
const overLimit = computed(() => {
    return boms.value && boms.value.length >= BOM_TOOL.MAX_PARTS;
});
const helpLink = PageHelpers.GetDocsUrl("my-account/");
const selectedBoms = computed<BOMSearchEdit[]>(() => {
    if (!Array.isArray(boms.value)) return [];

    return boms.value.filter((x) => x.IsSelected);
});
const selectedBom = computed<BOMSearchEdit | null>(() => {
    if (selectedBoms.value.length) {
        return selectedBoms.value[0] || null;
    } else {
        return null;
    }
});
const ownedSelectedBoms = computed(() => {
    return selectedBoms.value.filter((x) => x.BOMPermissionLevel == BOM_TOOL.BOM_PERMISSION_LEVELS.OWNER);
});
const sharedSelectedBoms = computed(() => {
    return selectedBoms.value.filter((x) => x.BOMPermissionLevel != BOM_TOOL.BOM_PERMISSION_LEVELS.OWNER);
});
const bomNames = computed(() => {
    return ownedSelectedBoms.value.map((x) => x.Name).join(", ");
});

function openStartNewBom() {
    navigateTo(startNewBOMLink.value, { external: true });
}
function openDistsDialog() {
    if (!bomDistsDialog.value) return;

    bomDistsDialog.value.openDialog();
}
function getBOMUrl(bom: BOMSearch) {
    return localePath(`/bom/${bom.HashId}`);
}
function editBom(bom: BOMSearchEdit) {
    if (!inputRefs.value) return;

    bom.EditMode = true;
    bom.OriginalName = bom.Name;

    const editItem = inputRefs.value.find((item) => item.id === `${BOM_NAME_PREFIX}${bom.HashId}`);

    nextTick(() => {
        editItem?.focus();
    });
}
async function saveBom(event: Event, bom: BOMSearchEdit) {
    if (!bom.Name.trim()) {
        event.preventDefault();
        event.stopPropagation();
        return;
    }

    if (bom.Name != bom.OriginalName) {
        try {
            await api<{ success: boolean }>(`/api/bom/${bom.HashId}`, {
                method: "PUT",
                body: bom,
            });
        } catch (_error) {
            error.value = t("Global.GenericError");
        }
    }

    bom.EditMode = false;
}
function deleteBOMs() {
    error.value = "";
    if (!bomConfirmDialog.value) return;

    bomConfirmDialog.value.openDialog({
        bomNames: bomNames.value,
        sharedSelectedBoms: sharedSelectedBoms.value,
    });
}
async function onDelete() {
    if (!Array.isArray(boms.value)) return;

    for (const bom of selectedBoms.value) {
        try {
            await api<{ success: boolean }>(`/api/bom/${bom.HashId}`, {
                method: "DELETE",
            });
        } catch (_error) {
            error.value = t("Global.GenericError");
        }
    }

    refreshBoms();
}
async function saveUserPreferences() {
    error.value = "";

    try {
        await api<{ success: boolean }>("api/account/bom-preferences", {
            method: "POST",
            body: {
                DefaultDistributorIds: selectedDists.value.map(function (d) {
                    return d.Id;
                }),
            },
        });
    } catch (_error) {
        error.value = t("Global.GenericError");
    }
}
function shareBom(bom: BOMSearchEdit) {
    if (!Array.isArray(boms.value)) return;

    for (const bomItem of boms.value) {
        bomItem.IsSelected = false;
    }
    bom.IsSelected = true;

    nextTick(() => {
        shareSelectedBoms();
    });
}
function isReadOnly(bom: BOMSearch) {
    return (
        bom.IsLocked ||
        bom.BOMPermissionLevel == BOM_TOOL.BOM_PERMISSION_LEVELS.VIEWER ||
        bom.BOMPermissionLevel == BOM_TOOL.BOM_PERMISSION_LEVELS.PUBLIC
    );
}
function shareSelectedBoms() {
    if (selectedBoms.value.length > 1 && bomMultiSharingDialog.value) {
        bomMultiSharingDialog.value.openDialog();
    } else if (bomSharingDialog.value) {
        bomSharingDialog.value.openDialog();
    }
}
function sharedUsersChanged(sharedUsers: BOMSearchUser[]) {
    if (selectedBom.value) {
        selectedBom.value.SharedUsers = sharedUsers;
    }
}
function enableLinkSharingChanged(changeStatus: boolean) {
    if (selectedBom.value) {
        selectedBom.value.EnableLinkSharing = changeStatus;
    }
}
function getBOMLockedMessage(bom: BOMSearch) {
    return t("BOMTool.BOMLockedMessage", [bom.CurrentUser]);
}
</script>
