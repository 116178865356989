<template>
    <Dialog v-model:open="isOpen">
        <DialogContent :id="'distributors-modal'" class="md:max-w-6xl max-h-[80vh]">
            <p class="flex gap-1">
                <span class="text-danger text-uppercase font-bold">{{ $t("BOMTool.DeleteBOM") }}:</span>
                <span>{{ bomNames }}</span>
            </p>
            <p>{{ $t("MyAccount.DeleteBomConfirmation") }}</p>
            <p v-for="(deleteMessage, index) in deleteMessages" :key="index" class="text-danger">
                {{ deleteMessage }}
            </p>
            <DialogFooter>
                <DialogClose as-child>
                    <Button variant="default">
                        {{ $t("Global.Close") }}
                    </Button>
                </DialogClose>
                <Button variant="danger" fill="solid" @click="onDelete">
                    {{ $t("BOMTool.YesDeleteThisBOM") }}
                </Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
</template>
<script setup lang="ts">
const { t } = useI18n();

const emit = defineEmits(["delete"]);
const isOpen = ref(false);
const bomNames = ref<string>("");
const sharedSelectedBoms = ref<BOMSearch[]>([]);

const deleteMessages = computed(() =>
    sharedSelectedBoms.value.map((bom) => t("BOMTool.BOMCannotBeDeletedError", [bom.Name]))
);

type DialogOptions = {
    bomNames: string;
    sharedSelectedBoms: BOMSearch[];
};

function openDialog(options: DialogOptions) {
    isOpen.value = true;
    bomNames.value = options.bomNames;
    sharedSelectedBoms.value = options.sharedSelectedBoms;
}

function onDelete() {
    isOpen.value = false;
    emit("delete");
}

defineExpose({ openDialog });
</script>
